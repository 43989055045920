<template>
    <div class="page" id="resourceIdentification">
        <div class="bg-white top">
            <el-row :gutter="20">
                <el-col :span="4">
                    <div class="lump">
                        <div class="catalogueBox rulesBox">
                            <el-table
                                    :data="catalogueList"
                                    ref="catalogueTable"
                                    size="small"
                                    :show-header=false
                                    height="calc(100vh - 180px)"
                                    class="table" highlight-current-row
                                    @current-change="directorySelection">
                                <el-table-column prop="total" label="资源鉴定">
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-col>
                <el-col :span="20">
                    <div class="lump baioge">
                        <el-row :gutter="20" class="headBox">
                            <el-col :span="5">
                                <el-input style="width: 100%" size="small" clearable v-model.trim="searchForm.name"
                                          maxlength="66" placeholder="请输入资源名称或编号"></el-input>
                            </el-col>
                            <el-col :span="4">
                                <el-select size="small" style="width: 100%" v-model="searchForm.type"
                                           placeholder="资源类型">
                                    <el-option
                                            v-for="item in options"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="9">
                                <el-date-picker
                                        style="width: 100%"
                                        size="small"
                                        value-format="yyyy-MM-dd"
                                        format="yyyy-MM-dd"
                                        v-model="searchForm.data"
                                        type="daterange"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                </el-date-picker>
                            </el-col>
                            <el-col :span="6">
                                <el-button type="primary" @click="submitForm('searchForm')" size="small"
                                           icon="el-icon-search">查询
                                </el-button>
                                <el-button @click="resetForm('searchForm')" size="small"
                                           icon="el-icon-refresh-right">重置
                                </el-button>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="14">
                                <AdvancedQuery ref="advancedQuery" :moduleId="moduleId" @getDataList="atlGetDataList">
                                </AdvancedQuery>
                            </el-col>
                            <el-col :span="10" class="text_right">
                                <el-button class="margin-distance" v-show="hasPermission('appraisal:list')"
                                           size="small" @click="appResources">鉴定
                                </el-button>
                                <el-button class="margin-distance" v-show="hasPermission('appraisal:list:fallback')"
                                           size="small" v-if="directorySelectiondd==2"
                                           @click="appBackOff">回退
                                </el-button>
                                <el-button class="margin-distance" v-show="hasPermission('appraisal:list:desensitization')"
                                           size="small" @click="appdesensitization(2)">
                                    脱敏处理
                                </el-button>
                                <el-button class="margin-distance" v-show="hasPermission('appraisal:list:cancelDesensitization')"
                                           size="small" @click="appdesensitization(3)">
                                    取消脱敏
                                </el-button>
                                <el-button size="small" plain icon="el-icon-refresh-left"
                                           @click="refreshAll"></el-button>
                                <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                            </el-col>
                        </el-row>
                        <el-table
                                :data="appraisalDataList"
                                v-loading="appraisalLoading"
                                size="small"
                                ref="rightList"
                                height="calc(100vh - 332px)"
                                @selection-change="appSelectionChangeHandle"
                                class="table">
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column v-for="(item,index) in columnList" :key="index"
                                             :prop="item.fieldEname" min-width="160"
                                             show-overflow-tooltip sortable
                                             :label="item.fieldName">
                                <template slot-scope="scope">
                                    <span v-if="item.fieldEname=='resource_type'">
                                        {{$dictUtils.getDictLabel("resource_type",scope.row.resource_type,'-')}}
                                    </span>
                                    <span v-else-if="item.fieldEname=='resource_state'">
                                            {{$dictUtils.getDictLabel("resource_state",scope.row.resource_state,'-')}}
                                    </span>
                                    <span v-else-if="item.fieldEname=='on_collection'">
                                            {{scope.row.on_collection ===  0 ? '否' : '是'}}
                                    </span>
                                    <span v-else-if="item.fieldEname=='update_user'">
                                            {{scope.row.updateUserName}}
                                    </span>
                                    <span v-else>{{scope.row[item.fieldEname]}}</span>
                                </template>
                            </el-table-column>


                            <el-table-column
                                    fixed="right"
                                    width="280"
                                    label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" style="margin-bottom:10px" type="primary" plain
                                               v-show="hasPermission('admin:resources:view')"
                                               @click="see(scope.row)">查看
                                    </el-button>
                                    <el-button size="mini" ref="evetada" v-if="directorySelectiondd!=3 && hasPermission('appraisal:list:open')" type="primary"
                                               plain
                                               @click="openUp($event,scope.row)">开放
                                    </el-button>
                                    <el-button size="mini" v-if="directorySelectiondd!=4 && hasPermission('appraisal:list:controlled')"
                                               type="primary" plain
                                               @click="control($event,scope.row)">受控
                                    </el-button>
                                    <el-button size="mini" type="primary" plain
                                               :disabled="!scope.row.desensitization"
                                               v-show="hasPermission('appraisal:list:desensitization')"
                                               @click="desensitization(2,scope.row.id,2)">脱敏处理
                                    </el-button>
                                    <el-button size="mini" type="primary" plain
                                               :disabled="!scope.row.cancelDesensitization"
                                               v-show="hasPermission('appraisal:list:cancelDesensitization')"
                                               @click="desensitization(3,scope.row.id,1)">取消脱敏
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="appSizeChangeHandle"
                                @current-change="appCurrentChangeHandle"
                                :current-page="appPageNo"
                                :page-sizes="[10, 20, 50, 100]"
                                :page-size="appPageSize"
                                :total="appTotal"
                                background
                                layout="total, sizes, prev, pager, next, jumper">
                        </el-pagination>
                    </div>
                </el-col>
            </el-row>
        </div>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="appraisalSetShow"
                      @getTbList="getTbList"></DraggablePop>
        <!--资源鉴定弹窗-->
        <el-dialog
                :title="resourceTitle"
                :visible.sync="handleDialogVisible"
                width="30%"
                :close-on-click-modal="false"
                :before-close="handleClose">
            <el-form size="small" :model="searchFormselsct" ref="searchFormselsct">
                <el-form-item :rules="[{required: zhanshi == 1 ? true : false, message: '请选择位置', trigger: 'change'}]"
                              v-show="zhanshi == 1" label="鉴定" prop="value">
                    <el-select size="small" v-model="searchFormselsct.value" placeholder="请选择位置">
                        <el-option
                                v-for="item in optionsSelect"
                                :key="item.value"
                                :disabled="item.disabled"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :rules="[{required: zhanshi != 1 ? true : false, message: '请选择脱敏字段', trigger: 'blur'}]"
                              v-show="zhanshi != 1" label="脱敏字段" prop="desensitization">
                    <el-select multiple size="small" v-model="searchFormselsct.desensitization" placeholder="请选择脱敏字段">
                        <el-option
                                v-for="item in tization"
                                :key="item.id"
                                :label="item.fieldName"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-row :gutter="20">
                    <el-col :span="4" :offset="6">
                        <el-button @click="closePopup('searchFormselsct')" size="small" type="primary">确定</el-button>
                    </el-col>
                    <el-col :span="4" :offset="4">
                        <el-button @click="quxiaos('searchFormselsct')" size="small">取消</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
        <!--        三维-->
        <!--查看-->
        <ViewThreeForm ref="viewThreeForm"></ViewThreeForm>

        <!--查看-->
        <ViewForm ref="viewForm"></ViewForm>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop2' // 导入排序弹窗
    import AdvancedQuery from '@/components/advancedQuery/advancedQuery' // 导入高级筛选弹窗
    import ViewThreeForm from '../gather/resource/three/viewThreeForm' // 三维查看弹窗
    import ViewForm from '../gather/resource/components/viewForm' // 查看弹窗
    export default {
        name: "resourceIdentification",
        components: {DraggablePop, AdvancedQuery, ViewThreeForm, ViewForm},
        data() {
            return {
                labelIndex: '0',
                tabPosition: 'left',
                options: [
                    {
                        value: '',
                        label: '全部',
                    },
                    {
                        value: 1,
                        label: '图片',
                    },
                    {
                        value: 5,
                        label: '文献',
                    },
                    {
                        value: 3,
                        label: '视频',
                    },
                    {
                        value: 2,
                        label: '音频',
                    },
                    {
                        value: 0,
                        label: '三维文件',
                    },
                    {
                        value: 4,
                        label: '出版物',
                    },
                    {
                        value: 6,
                        label: '文件',
                    },
                    {
                        value: 7,
                        label: '其它',
                    },
                ],

                catalogueList: [
                    {
                        value: 0,
                        total: '未鉴定资源',
                    },
                    {
                        value: 1,
                        total: '开放资源',
                    },
                    {
                        value: 2,
                        total: '受控资源',
                    }
                ],

                searchForm: {
                    name: '',
                    type: '',
                    data: '',
                },
                columnList: [],
                appraisalDataList: [],
                appraisalLoading: false,
                appDataListSelections: [],//多选
                moduleId: '958321248549470208',   // 当前请求拖拽排序数据id
                appraisalSetShow: false,
                appSelectAllShow: false,//是否全选
                appPageNo: 0,
                appPageSize: 10,
                appTotal: 0,
                directorySelectiondd: 2,//目录选择

                //资源鉴定
                handleDialogVisible: false,
                resourceTitle: '资源鉴定',
                zhanshi: '',
                searchFormselsct: {
                    value: '',
                    desensitization: '',
                },
                rulesselect: {
                    value: [
                        {required: true, message: '请选择位置', trigger: 'change'}
                    ],
                    desensitization: [
                        {required: true, message: '请选择脱敏字段', trigger: 'blur'}
                    ],
                },
                optionsSelect: [
                    {
                        label: '未鉴定资源',
                        value: 2,
                        disabled: false,
                    },
                    {
                        label: '开放资源',
                        value: 3,
                        disabled: false,
                    },
                    {
                        label: '受控资源',
                        value: 4,
                        disabled: false,
                    },
                ],
                tization: [],
                shujuId: [],
                searchTpye: 0,
                highData: null,
                onScene: false, // 是否正在使用场景
                dataListQuery: [], // 临时储存场景数据
            }
        },
        mounted() {
            this.autoHighlight();
            this.appGetLiat()
            // this.getColumnList()
        },
        methods: {
            //左边目录页默认高亮第一行
            autoHighlight() {
                this.$refs.catalogueTable.setCurrentRow(this.catalogueList[0])
            },

            //目录列表选中的那一项
            directorySelection(news) {
                this.optionsSelect.map((item, index) => {
                    item.disabled = false;
                    if (news.value == index) {
                        item.disabled = true;
                    }
                })
                // console.log('新选中的数据', news);
                if (news.value == 0) {
                    this.directorySelectiondd = 2;
                } else if (news.value == 1) {
                    this.directorySelectiondd = 3;
                } else if (news.value == 2) {
                    this.directorySelectiondd = 4;
                }
                this.searchForm.name = ''
                this.searchForm.type = ''
                this.searchForm.data = ''
                this.appPageNo = 0;
                this.appTotal = 0;
                this.appGetLiat();
            },


            //查询
            submitForm(formName) {
                /*this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.appPageNo = 0;
                        this.appTotal = 0;
                        this.appGetLiat();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });*/
                this.appPageNo = 0;
                this.appTotal = 0;
                this.appGetLiat();
            },

            //单个数据查看
            see(row) {
                if (row.resource_type == 0) { //三维
                    this.$refs.viewThreeForm.init(row);
                } else {
                    this.$refs.viewForm.init(row.id, row.resource_type, this.moduleId);
                }
            },

            //单个数据开放
            openUp(event, row) {
                this.$confirm('此操作将开放该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //鼠标点击按钮后，按钮的默认选中
                    let target = event.target;
                    if (target.nodeName == "SPAN") {
                        target = event.target.parentNode;
                    }
                    target.blur();

                    this.shujuId = [];
                    this.shujuId.push(row.id);
                    this.$axios(this.api.dyyg.appraisalOrGoBack, {
                        resourceState: 3,
                        resourcesIds: this.shujuId,
                        goBackReason: '',
                    }, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('资源开放成功')
                            if (Math.ceil(this.appTotal / this.appPageSize) == this.appPageNo && this.shujuId.length == this.appraisalDataList.length && this.appPageNo != 0) {
                                this.appPageNo = this.appPageNo - 1;
                            }
                            this.publishList();
                        } else {
                            this.$message.error('资源开放失败')
                        }
                        this.appGetLiat();
                    })
                }).catch(() => {
                });
            },

            publishList() {
                this.$axios(this.api.dyyg.publishList, {
                    'current': 1,
                    'size': 10,
                    'search': '',
                    'year': '',
                    'type': '',
                    'image': '',
                    'threeType': ''
                }, 'get').then(res => {
                    if (res.status) {
                    }
                })
            },

            //单个数据受控
            control(event, row) {
                this.$confirm('此操作将受控该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //鼠标点击按钮后，按钮的默认选中
                    let target = event.target;
                    if (target.nodeName == "SPAN") {
                        target = event.target.parentNode;
                    }
                    target.blur();

                    this.shujuId = [];
                    this.shujuId.push(row.id);
                    this.$axios(this.api.dyyg.appraisalOrGoBack, {
                        resourceState: 4,
                        resourcesIds: this.shujuId,
                        goBackReason: '',
                    }, 'post').then(res => {
                        if (res.status) {
                            this.$message.success('资源受控成功')
                            if (Math.ceil(this.appTotal / this.appPageSize) == this.appPageNo && this.shujuId.length == this.appraisalDataList.length && this.appPageNo != 0) {
                                this.appPageNo = this.appPageNo - 1;
                            }
                            this.publishList();
                        } else {
                            this.$message.error('资源受控失败')
                        }
                        this.appGetLiat();
                    })
                }).catch(() => {
                });
            },

            // 获取显示字段
            // getColumnList() {
            //     var that = this;
            //     that.columnList = [];
            //     that.$axios(this.api.dyyg.getByOriginalId + this.moduleId, '', "get").then((res) => {
            //         if (res.status) {
            //             res.data.map(item => {
            //                 if (item.logicType == 0) {
            //                     this.columnList.push(item);
            //                 }
            //             })
            //             this.$nextTick(() => {
            //                 this.$refs.rightList.doLayout()
            //             })
            //         }
            //     })
            // },

            // 刷新按钮回到全部列表
            refreshAll() {
                this.onScene = false
                this.dataListQuery.queryConditions = []
                this.dataListQuery.sceneId = ''
                this.searchForm.data = []
                this.searchForm.type = ''
                this.searchForm.name = ''
                this.appPageNo = 0
                this.appGetLiat()
            },
            //未鉴定资源
            //列表数据请求
            appGetLiat() {
                if (this.searchForm.data == null) {
                    this.searchForm.data = []
                }
                /*if (this.onScene || (this.dataListQuery.queryConditions != null && this.dataListQuery.queryConditions.length > 0)) {
                    this.getDataList(this.dataListQuery)
                    return
                }*/
                this.searchTpye = 0
                this.appraisalLoading = true;
                this.$axios(this.api.dyyg.resourceIdentificationList, {
                    current: this.appPageNo,
                    resourceNameOrId: this.searchForm.name,
                    resourceType: this.searchForm.type,
                    size: this.appPageSize,
                    startTime: this.searchForm.data[0],
                    endTime: this.searchForm.data[1],
                    resourceState: this.directorySelectiondd,
                }, 'get').then(res => {
                    if (res.status) {
                        this.appraisalLoading = false;
                        this.appraisalDataList = res.data.records;
                        this.appTotal = parseInt(res.data.total);
                    }
                })
            },

            // 多选
            appSelectionChangeHandle(val) {
                this.appDataListSelections = val;
            },

            // 每页数
            appSizeChangeHandle(val) {
                this.appPageSize = val
                this.appPageNo = 0
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.appGetLiat();
                }
            },

            // 当前页
            appCurrentChangeHandle(val) {
                this.appPageNo = val
                if (this.onScene) {
                    this.getDataList(this.dataListQuery)
                } else {
                    this.appGetLiat();
                }
            },

            //鉴定
            appResources() {
                if (this.appDataListSelections.length == 0) {
                    this.$message.warning('请选择至少一条数据')
                } else {
                    this.shujuId = [];
                    this.appDataListSelections.map(item => {
                        this.shujuId.push(item.id);
                    })
                    this.zhanshi = 1;
                    this.resourceTitle = '资源鉴定';
                    this.handleDialogVisible = true;
                }
            },

            //回退
            appBackOff() {
                if (this.appDataListSelections.length == 0) {
                    this.$message.warning('请选择至少一条数据')
                } else {
                    this.$confirm('此操作将退回该数据, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.shujuId = [];
                        this.appDataListSelections.map(item => {
                            this.shujuId.push(item.id);
                        });
                        this.$axios(this.api.dyyg.appraisalOrGoBack, {
                            resourceState: 1,
                            resourcesIds: this.shujuId,
                            goBackReason: '',
                        }, 'post').then(res => {
                            if (res.status) {
                                this.$message.success('资源回退成功')
                                if (Math.ceil(this.appTotal / this.appPageSize) == this.appPageNo && this.shujuId.length == this.appraisalDataList.length && this.appPageNo != 0) {
                                    this.appPageNo = this.appPageNo - 1;
                                }
                                this.publishList();
                            } else {
                                this.$message.error('资源回退失败')
                            }
                            this.appGetLiat();
                        })
                    }).catch(() => {
                    });
                }
            },

            //脱敏处理
            appdesensitization(zhanshi) {
                if (this.appDataListSelections.length == 0) {
                    this.$message.warning('请选择至少一条数据')
                } else {
                    this.shujuId = [];
                    this.appDataListSelections.map(item => {
                        this.shujuId.push(item.id);
                    })
                    this.zhanshi = zhanshi;
                    this.resourceTitle = zhanshi == 2 ? '脱敏处理' : '取消脱敏';
                    this.$axios(this.api.dyyg.getTableByResourceName, {
                        resourceName: '资源鉴定/脱敏处理',
                    }, 'get').then(res => {
                        if (res.status) {
                            this.tization = res.data.data;
                        }
                    })
                    this.handleDialogVisible = true;
                }
            },
            // 单个脱敏或取消
            desensitization(zhanshi, id, type) {
                this.shujuId = [];
                this.shujuId.push(id);
                this.zhanshi = zhanshi;
                this.resourceTitle = zhanshi == 2 ? '脱敏处理' : '取消脱敏';
                this.$axios(this.api.dyyg.echoField, {
                    'resourceId': id,
                    'type': type
                }, 'get').then(res => {
                    if (res.status) {
                        this.tization = res.data;
                    }
                })
                this.handleDialogVisible = true;
            },

            //资源鉴定
            handleClose() {
                this.quxiaos('searchFormselsct');
                this.handleDialogVisible = false;
            },

            //确定
            closePopup(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.zhanshi == 1) { //鉴定
                            this.$axios(this.api.dyyg.appraisalOrGoBack, {
                                resourceState: this.searchFormselsct.value,
                                resourcesIds: this.shujuId,
                                goBackReason: '',
                            }, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('资源鉴定成功')
                                    if (Math.ceil(this.appTotal / this.appPageSize) == this.appPageNo && this.shujuId.length == this.appraisalDataList.length && this.appPageNo != 0) {
                                        this.appPageNo = this.appPageNo - 1;
                                    }
                                    this.publishList();
                                } else {
                                    this.$message.error('资源鉴定失败')
                                }
                                this.appGetLiat();
                            })
                        } else if (this.zhanshi == 2) {
                            //脱敏
                            this.$axios(this.api.dyyg.encryptionFieldSave, {
                                originalIds: this.searchFormselsct.desensitization,
                                resourceIds: this.shujuId,
                            }, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('脱敏成功')
                                } else {
                                    this.$message.error('脱敏失败')
                                }
                                this.appGetLiat();
                            })
                        } else {
                            //取消脱敏
                            this.$axios(this.api.dyyg.cancelEncryption, {
                                originalIds: this.searchFormselsct.desensitization,
                                resourceIds: this.shujuId,
                            }, 'post').then(res => {
                                if (res.status) {
                                    this.$message.success('取消脱敏成功')
                                } else {
                                    this.$message.error('取消脱敏失败')
                                }
                                this.appGetLiat();
                            })
                        }
                        this.$refs[formName].resetFields();
                        this.handleDialogVisible = false;

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //取消
            quxiaos(formName) {
                this.$refs[formName].resetFields();
                this.handleDialogVisible = false;
            },


            //未定
            //重置
            resetForm(formName) {
                this.searchForm.name = ''
                this.searchForm.type = ''
                this.searchForm.data = []
                //this.$refs[formName].resetFields();
                this.dataListQuery = []
                this.$refs.advancedQuery.addForm.sceneId = ''
                this.$nextTick(() => {
                    this.dataListQuery.queryConditions = []
                    this.appGetLiat();
                })
            },
            // 高级查询
            atlGetDataList(data) {
                this.appPageNo = 0
                this.getDataList(data)
            },
            // 获取高级查询条件
            getDataList(data) {
                if (data != null && data.sceneId != null && data.sceneId != '') {
                    this.onScene = true
                } else {
                    this.onScene = false
                }
                if (data != null && data.clearCurrent != null && data.clearCurrent == true) {
                    this.pageNo = 0
                }
                this.dataListQuery = data
                this.searchTpye = 0
                data.size = this.appPageSize
                data.current = this.appPageNo
                data.resourceState = this.directorySelectiondd
                data.searchNumOrName = this.searchForm.name
                data.type = this.searchForm.type
                data.startTime = this.searchForm.data[0]
                data.endTime = this.searchForm.data[1]

                this.$axios(this.api.dyyg.appraisalQueryResource, JSON.stringify(data), 'post').then(res => {
                    if (res && res.status) {
                        this.appraisalDataList = res.data.records;
                        this.appTotal = parseInt(res.data.total);
                    }
                })
            },

            // 设置
            setTb() {
                this.appraisalSetShow = true;
            },

            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.columnList = data;
                this.appraisalSetShow = state;
                this.$nextTick(() => {
                    this.$refs.rightList.doLayout()
                    console.log(this.columnList)
                })
                //this.appGetLiat();
            },
        }
    }
</script>

<style>

</style>
